import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Grid,
    TextField,
    Typography
} from '@mui/material'
import { CiCirclePlus } from 'react-icons/ci'
import { MdClear } from 'react-icons/md'
import ConfirmDialog from '../../../layouts/ConfirmDialog'
import { IoSaveOutline } from 'react-icons/io5'
import PlanterGroup from '../../mangrove_planter/components/popupView/PlanterGroup'
import { useNavigate } from 'react-router-dom'
import Controls from '../../../components/Entry/Controls'
import SigleForm from './popupView/SigleForm'

const useStyles = makeStyles((theme) => ({
    pageHeader: {
        textTransform: 'uppercase',
        textAlign: 'center',
        fontWeight: 'semibold',
        '& .MuiCardHeader-title': {
            fontSize: '1.1rem'
        }
    }
}))

const ConditionalCard = ({
    title,
    parentName,
    createNursery,
    setCreateNursery,
    items,
    enabledItem,
    selectedItem,
    handleAddField,
    handleDoubleClick,
    setGroupSaved,
    groupSaved,
    handleChange,
    handleShowItems,
    handleKeyDown,
    handleClearItem,
    isLoading,
    selectedVillageForGroup = null
}) => {
    const classes = useStyles()
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
    const [openPopup, setOpenPopup] = useState(false)
    const [selectedGroupCategory, setSelectedGroupCategory] = useState('MangroGroup')
    const [groupCategory, setGroupCategory] = useState('mangrove')
    const [selectedPlantersCategory, setSelectedPlantersCategory] = useState('MangroPlanter')
    const [recordsGroup, setRecordsGroup] = useState(null)
    const [indexedVillages, setIndexedVillages] = useState(null)
    const [userHaveToAdd, setUserHaveToAdd] = useState(false)
    const [openSiglePopup, setOpenSiglePopup] = useState(false)

    const navigate = useNavigate()

    const planterChoosenAction = (code) => {
        navigate(`/app/mangrove/planter/information/${code}`)
        setOpenPopup(false)
    }

    useEffect(() => {
        if (groupSaved) {
            console.log('changemement: ', groupSaved)
        }
    }, [groupSaved])

    useEffect(() => {
        if (selectedVillageForGroup) {
            console.log('village n : ', selectedVillageForGroup[`${selectedGroupCategory}`])
            setRecordsGroup({
                ...selectedVillageForGroup,
                groups: selectedVillageForGroup[`${selectedGroupCategory}`].map((item) => {
                    return {
                        code: item.name,
                        ...item,
                        planters: item[`${selectedPlantersCategory}`],
                        planter_number: item[`${selectedPlantersCategory}`].length
                    }
                }),
                category: selectedGroupCategory
            })
        }
    }, [selectedVillageForGroup, selectedGroupCategory])

    return (
        <>
            <Card className="min-h-[530px]">
                <CardHeader
                    className={classes.pageHeader}
                    title={
                        <div className="flex items-center justify-center gap-2">
                            {title}
                            {isLoading && <CircularProgress size={16} className="mr-2" />}
                        </div>
                    }
                />
                <CardContent>
                    <div className="h-96 overflow-y-auto rounded-md scrollbar-w-8 text-xs text-center">
                        {items ? (
                            items.length > 0 ? (
                                items.map((item, index) => (
                                    <div key={index}>
                                        {selectedVillageForGroup && indexedVillages === index && (
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    ':hover': { cursor: 'pointer', color: '#0fcb8f' }
                                                }}
                                                color="text.secondary"
                                                onClick={() => {
                                                    console.log(item)
                                                    setOpenSiglePopup(true)
                                                }}
                                            >
                                                <i>
                                                    <u>Sigle village</u>
                                                </i>
                                            </Typography>
                                        )}
                                        <div className="flex" key={index}>
                                            <div key={index} className="flex mr-[5px] relative">
                                                <TextField
                                                    id={`outlined-basic-${index}`}
                                                    variant="outlined"
                                                    size="small"
                                                    name="name"
                                                    sx={{
                                                        marginBottom: 1,
                                                        input: {
                                                            cursor: enabledItem === item ? 'text' : 'pointer'
                                                        },
                                                        backgroundColor: selectedItem === item ? '#0FCB8F' : 'inherit',
                                                        flex: 1
                                                    }}
                                                    value={item.name}
                                                    onChange={(event) => handleChange(event, item)}
                                                    onDoubleClick={() => handleDoubleClick(item)}
                                                    onClick={() => {
                                                        handleShowItems(item)
                                                        setIndexedVillages(index)
                                                    }}
                                                    // onKeyDown={(event) => handleKeyDown(event, item)}
                                                />
                                                {item && (
                                                    <MdClear
                                                        className="cursor-pointer w-4 h-4 border-2 border-gray-300 rounded-full text-gray-500 bg-gray-300 mb-15 absolute right-1 top-1"
                                                        onClick={() => {
                                                            setUserHaveToAdd(false)
                                                            setCreateNursery && setCreateNursery(false)
                                                            setConfirmDialog({
                                                                isOpen: true,
                                                                successMessage: 'Suppression en cours ...',
                                                                title: 'Vous voulez vraiment le supprimer?',
                                                                subTitle: 'Cette opération est irréversible.',
                                                                onConfirm: () => {
                                                                    handleClearItem(item)
                                                                    setConfirmDialog({ isOpen: false })
                                                                }
                                                            })
                                                        }}
                                                    />
                                                )}
                                            </div>
                                            <div className=" mt-[12px]">
                                                <IoSaveOutline
                                                    onClick={(event) => {
                                                        handleKeyDown(event, item)
                                                        setUserHaveToAdd(false)
                                                    }}
                                                    className=" w-4 h-4 cursor-pointer"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className="italic">Aucun résultat</p>
                            )
                        ) : (
                            <p className="italic">Veuillez sélectionner une {parentName}</p>
                        )}
                    </div>
                    {title === 'villages' && userHaveToAdd && (
                        <Controls.Checkbox
                            label="Créer une pépinière (Agro)"
                            name="nursery"
                            value={createNursery}
                            onChange={(e) => {
                                setCreateNursery(e.target.value)
                            }}
                        />
                    )}
                </CardContent>
                <CardActions disableSpacing className="flex justify-center ">
                    {items && (
                        <CiCirclePlus
                            className="w-8 h-8 cursor-pointer"
                            onClick={() => {
                                handleAddField()
                                setUserHaveToAdd(true)
                            }}
                        />
                    )}
                </CardActions>
                {selectedVillageForGroup && (
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography
                                variant="caption"
                                sx={{
                                    margin: '10px 0 10px 0',
                                    ':hover': { cursor: 'pointer', color: '#0fcb8f' },
                                    textAlign: 'center'
                                }}
                                component="div"
                                color="text.secondary"
                                onClick={() => {
                                    setSelectedGroupCategory('AgroGroups')
                                    setSelectedPlantersCategory('Peasants')
                                    setGroupCategory('agroforesterie')
                                    setOpenPopup(true)
                                }}
                            >
                                <i>
                                    <u>Groupe agroforesterie</u>
                                </i>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                variant="caption"
                                sx={{
                                    margin: '10px 0 10px 0',
                                    ':hover': { cursor: 'pointer', color: '#0fcb8f' },
                                    textAlign: 'center'
                                }}
                                component="div"
                                color="text.secondary"
                                onClick={() => {
                                    setSelectedGroupCategory('MangroGroup')
                                    setSelectedPlantersCategory('MangroPlanter')
                                    setGroupCategory('mangrove')
                                    setOpenPopup(true)
                                }}
                            >
                                <i>
                                    <u>Groupe mangrove</u>
                                </i>
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
                {recordsGroup && openPopup && (
                    <PlanterGroup
                        setGroupSaved={setGroupSaved}
                        category={groupCategory}
                        isGroupManagement={true}
                        records={recordsGroup}
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                        actions={(code) => planterChoosenAction(code)}
                    />
                )}
            </Card>
            <div>
                <SigleForm
                    openPopup={openSiglePopup}
                    setOpenPopup={setOpenSiglePopup}
                    item={selectedVillageForGroup}
                    setGroupSaved={setGroupSaved}
                />
            </div>
        </>
    )
}

export default ConditionalCard
